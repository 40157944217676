import { useDeviceStore } from "@state/deviceStore"
import trimData from "./trimData"

export default async function fetchApartments() {
  const setApartments = useDeviceStore((state) => state.setApartments)

  try {
    const unitsDataResponse = await fetch(
      "https://api-gateway.vmiservers.com/boardwalk/units"
    )
    if (!unitsDataResponse.ok) {
      throw new Error("Failed to fetch units data")
    }
    const unitsDataJSON = await unitsDataResponse.json()
    const unitsData = unitsDataJSON.payload

    // Set units data to apartments
    setApartments(trimData(unitsData))

    // Fetch real estate tax data
    const reTaxesDataResponse = await fetch(
      "https://api-gateway.vmiservers.com/boardwalk/standardfields/8505"
    )
    if (!reTaxesDataResponse.ok) {
      throw new Error("Failed to fetch real estate tax data")
    }
    const reTaxesDataJSON = await reTaxesDataResponse.json()
    const reTaxesData = reTaxesDataJSON.payload

    if (reTaxesData) {
      const reTaxes = reTaxesData["additional_fields"]

      // Map through unitsData and add real estate tax value if matched
      const apartmentsWithTax = trimData(unitsData).map((mapEntry) => {
        const inventoryID = mapEntry["inventoryID"]
        const matchedTax = reTaxes.find(
          (taxEntry) => taxEntry["inventory_id"] === inventoryID
        )

        if (matchedTax) {
          return {
            ...mapEntry,
            realEstateTax: matchedTax["value"],
          }
        } else {
          return {
            ...mapEntry,
            realEstateTax: null,
          }
        }
      })

      // Fetch common charges data only if reTaxes data was successfully fetched
      const commonChargesDataResponse = await fetch(
        "https://api-gateway.vmiservers.com/boardwalk/standardfields/8506"
      )
      if (!commonChargesDataResponse.ok) {
        throw new Error("Failed to fetch common charges data")
      }
      const commonChargesDataJSON = await commonChargesDataResponse.json()
      const commonChargesData = commonChargesDataJSON.payload

      if (commonChargesData) {
        const commonCharges = commonChargesData["additional_fields"]

        // Map through apartmentsWithTax and add common charges if matched
        const apartmentsWithTaxAndCharges = apartmentsWithTax.map(
          (apartmentEntry) => {
            const inventoryID = apartmentEntry["inventoryID"]
            const matchedCharge = commonCharges
              ? commonCharges.find(
                  (chargeEntry) => chargeEntry["inventory_id"] === inventoryID
                )
              : null

            if (matchedCharge) {
              return {
                ...apartmentEntry,
                commonCharges: matchedCharge["value"],
              }
            } else {
              return {
                ...apartmentEntry,
                commonCharges: null,
              }
            }
          }
        )

        setApartments(apartmentsWithTaxAndCharges)
        console.log(apartmentsWithTaxAndCharges)
      }
    }
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error)
  }
}
