//General
import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"

//Components
import BackArrow from "@components/SVG/BackArrow"
import { Link } from "react-router-dom"
import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"

//Types
interface StartTypes {
  isPortrait?: boolean
}

export default function GalleryAmenities() {
  const syncState = useSocketStore((state) => state.syncState)
  const socket = useSocketStore((state) => state.socket)
  const room = useSocketStore((state) => state.room)
  const emitSocketEvent = useSocketStore((state) => state.emitSocketEvent)
  const setEmbla = useSocketStore((state) => state.setEmbla)
  const isIpad = useDeviceStore((s) => s.isIpad)

  const galleryNumbers = [
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
    "Amenities",
  ]

  //Initiate carousel
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    loop: true,
    speed: 20,
  })

  const onSelect = useCallback(
    (eventName) => {
      if (emblaApi) {
        const id = emblaApi.selectedScrollSnap()
        syncState("currentAmenityIndex", id)
        setEmbla(id)
      }
    },
    [emblaApi]
  )

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect)
    }
  }, [emblaApi, onSelect])

  useEffect(() => {
    socket.on("got-embla", ({ data }) => {
      // console.log(data)
      if (emblaApi) {
        // console.log("current id is: ", emblaApi.selectedScrollSnap())
        // console.log("incoming id is: ", data)
        if (data !== emblaApi.selectedScrollSnap()) {
          emblaApi.scrollTo(data)
        }
      }
    })
  }, [emblaApi])

  return (
    <>
      <Link
        to="/gallery"
        onClick={() => {
          emitSocketEvent("navigation", {
            path: "/gallery",
            room: room,
          })
        }}
      >
        <StyledBackArrow />
      </Link>
      {isIpad && <DragToScroll>DRAG TO SCROLL →</DragToScroll>}
      <Embla ref={emblaRef}>
        <EmblaContainer>
          {galleryNumbers.map((label, index) => {
            return (
              <EmblaSlide key={index}>
                <GalleryImage
                  src={`/media/gallery/amenities/${index + 1}.webp`}
                  alt=""
                  label={label}
                />
              </EmblaSlide>
            )
          })}
        </EmblaContainer>
      </Embla>
    </>
  )
}

function GalleryImage({ src, alt, label }) {
  const [isPortrait, setIsPortrait] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.src = src

    img.onload = function () {
      setIsPortrait(img.height > img.width)
    }
  }, [src]) // The effect depends on the src prop

  return (
    <>
      <FullImage isPortrait={isPortrait} src={src} alt={alt} />
    </>
  )
}

const DragToScroll = styled.div<StartTypes>`
  position: absolute;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-family: "Gilmer", sans-serif;
  padding: 0.7rem 2rem 0.7rem 2rem;
  bottom: 3%;
  right: 3%;
  background-color: ${(props) => props.theme.colors.green};
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 100px;
  z-index: 3;
  font-weight: 400;
`

const StyledBackArrow = styled(BackArrow)<StartTypes>`
  position: absolute;
  bottom: 3%;
  left: 3%;
  z-index: 3;
  width: 10rem;
  height: 4rem;
`

const Embla = styled.section<StartTypes>`
  height: 100%;
  width: 100%;
  & img {
    pointer-events: none;
  }
`

const EmblaContainer = styled.div<StartTypes>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
`

const EmblaSlide = styled.div<StartTypes>`
  flex: 0 0 100%;
  min-width: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.black};
  display: grid;
  place-items: center;
  grid-template-columns: minmax(0, 7.5fr) minmax(0, 1.4fr) minmax(0, 0.5fr);
  grid-template-rows: minmax(0, 0.5fr) minmax(0, 0.5fr) minmax(0, 9fr);

  .ImageLabel {
    height: 100%;
    width: 100%;
    grid-column: 2;
    grid-row: 2;
    background-color: ${(props) => props.theme.colors.green};
    border: 0.2rem solid ${(props) => props.theme.colors.white};
    display: grid;
    place-items: center;
    & p {
      font-size: 1vw;
      text-align: center;
      font-family: "Gilmer", sans-serif;
      padding: 0.7rem 2rem 0.7rem 2rem;
      color: ${(props) => props.theme.colors.white};
      display: grid;
      place-items: center;
    }
  }
`

const FullImage = styled.img<StartTypes>`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: ${(props) => (props.isPortrait ? "auto" : "100%")};
  object-fit: cover;
  overflow: hidden;
`
